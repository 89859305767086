// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any)['__zone_symbol__PASSIVE_EVENTS'] = [
  'scroll',
  'mousemove',
  'touchmove',
  'touchstart',
  'mouseenter',
  'touchend',
  'touchcancel',
  'mouseleave',
];
